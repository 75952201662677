import { transChoice } from '@plenny/translator';

export enum Unit {
  // Length related units.
  KILOMETER = 'KILOMETER',
  CENTIMETER = 'CENTIMETER',
  METER = 'METER',
  MILIMETER = 'MILIMETER',
  LINEAR_METER = 'LINEAR_METER',

  // Weight related units.
  TON = 'TON',
  KILOGRAM = 'KILOGRAM',
  DEKAGRAM = 'DEKAGRAM',
  GRAM = 'GRAM',
  MILIGRAM = 'MILIGRAM',

  // Area related units.
  METER_SQUARED = 'METER_SQUARED',

  // Geodetic units.
  HECTARE = 'HECTARE',
  ARE = 'ARE',

  // Capacity related units.
  METER_CUBED = 'METER_CUBED',
  LITRE = 'LITRE',
  MILILITRE = 'MILILITRE',

  // Time related units.
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',

  // Standard shop unit.
  PIECE = 'PIECE',
  SET = 'SET',


  // IMPERIAL UNITS
  // Length
  FOOT = 'FOOT',
  INCH = 'INCH',

  // Capacity
  US_GALLON = 'US_GALLON',
  US_PINT = 'US_PINT',
  UK_GALLON = 'UK_GALLON',
  UK_PINT = 'UK_PINT',

  // Weight
  POUND = 'POUND',
  OUNCE = 'OUNCE',
}

export function unitsDictionary() {
  return Object.values(Unit).map((unit: Unit) => {
    return { value: unit, label: transChoice(`UNIT.${unit}.LONG`, 1) };
  });
}

export function convertiblesDictionary(unit: Unit) {
  return convertibles(unit).map((unit: Unit) => {
    return { value: unit, label: transChoice(`UNIT.${unit}.LONG`, 1) };
  });
}

export function convertibles(unit: Unit): Unit[] {
  switch (unit) {
    case Unit.MILIMETER:
    case Unit.METER:
    case Unit.CENTIMETER:
    case Unit.KILOMETER:
    case Unit.FOOT:
    case Unit.INCH:
      return [
        Unit.MILIMETER,
        Unit.METER,
        Unit.CENTIMETER,
        Unit.KILOMETER,
        Unit.FOOT,
        Unit.INCH,
      ];

    case Unit.LINEAR_METER:
      return [
        Unit.LINEAR_METER,
      ];

    case Unit.MILIGRAM:
    case Unit.GRAM:
    case Unit.DEKAGRAM:
    case Unit.KILOGRAM:
    case Unit.TON:
    case Unit.POUND:
    case Unit.OUNCE:
      return [
        Unit.MILIGRAM,
        Unit.GRAM,
        Unit.DEKAGRAM,
        Unit.KILOGRAM,
        Unit.TON,
        Unit.POUND,
        Unit.OUNCE,
      ];

    case Unit.METER_SQUARED:
    case Unit.ARE:
    case Unit.HECTARE:
      return [
        Unit.METER_SQUARED,
        Unit.ARE,
        Unit.HECTARE,
      ];

    case Unit.METER_CUBED:
    case Unit.LITRE:
    case Unit.MILILITRE:
    case Unit.US_GALLON:
    case Unit.US_PINT:
    case Unit.UK_GALLON:
    case Unit.UK_PINT:
      return [
        Unit.METER_CUBED,
        Unit.LITRE,
        Unit.MILILITRE,
        Unit.US_GALLON,
        Unit.US_PINT,
        Unit.UK_GALLON,
        Unit.UK_PINT,
      ];

    case Unit.MONTH:
      return [
        Unit.MONTH,
      ];

    case Unit.WEEK:
      return [
        Unit.WEEK,
        Unit.DAY,
        Unit.HOUR,
        Unit.MINUTE,
      ];

    case Unit.DAY:
      return [
        Unit.DAY,
        Unit.HOUR,
        Unit.MINUTE,
      ];

    case Unit.HOUR:
      return [
        Unit.HOUR,
        Unit.MINUTE,
      ];

    case Unit.MINUTE:
      return [
        Unit.MINUTE,
      ];

    case Unit.PIECE:
      return [
        Unit.PIECE,
      ];

    case Unit.SET:
      return [
        Unit.SET,
      ];
  }
}
